import React from 'react';
import Site from '../../../Layouts/Site/Site';
import { Link } from 'react-router-dom';
import ActingClients from './Acting.clients';
import ActingTopics from './Acting.topics';
import '../../../styles/pages/acting.css';

const Acting = () => {

    return (
        <Site>
            <main className="container">
                <div className="container-acting">
                    <h2>Atuação</h2>
                    <div className="content">
                        <section className="acting-items" >
                            <div className="card">
                                <div className="card-header">
                                    Atuamos com
                                    </div>
                                <div className="card-body">
                                    <p className="card-text">- Recuperação tributária de tributos Municipais, Estaduais e Federais; </p>
                                    <p className="card-text">- Defesa Administrativa e judicial do contribuinte;</p>
                                    <p className="card-text">- Análise das normas tributárias para planejamento fiscal;</p>
                                    <p className="card-text">- Atendimento a fiscalização tributária;</p>
                                    <p className="card-text">- Elaboração de solução de consulta tributária perante autoridades fazendárias;</p>
                                    <p className="card-text">- Repatriação de bens e direitos do contribuinte localizado no exterior (Análise dos protocolos entre países);</p>
                                </div>
                                <div className="card-footer"><small className="text-muted">Tem dúvidas ? <Link to="contacts" > Fale consoco </Link> </small></div>
                            </div>

                        </section>
                        <ActingTopics />
                        <ActingClients />
                    </div>

                </div>

            </main>
        </Site>
    )
}

export default Acting;