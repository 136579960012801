import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ModalProps {
    showModal: boolean,
    responseModal: (foo: boolean) => void
}

const ModalConfirm: React.FC<ModalProps> = ({ showModal, responseModal }) => {
    const [show, setShow] = useState(false);
    function handleClose() {
        setShow(false);
        responseModal(false);
    };

    function handleConfirm() {
        setShow(false);
        responseModal(true);
    }

    useEffect(() => {
        setShow(showModal);
    }, [showModal])


    return (

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            key={(new Date()).getMilliseconds()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Excluir</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Você tem certeza que deseja excluir esse item ?
                Esta ação não tem reversão !
          </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Não
                    </Button>
                <Button variant="danger" onClick={handleConfirm} >
                    Sim, tenho</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirm;