import React from 'react';
import Site from '../../../Layouts/Site/Site';
import '../../../styles/pages/videos.css';
import Main from './Videos.Main'
const Videos = () => {
    return (
        <Site>
           <Main/>
        </Site>
    )
}

export default Videos;