import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormAuth from '../../components/Form/FormAuth';
import Input from '../../components/Inputs/Input';
import useAuth from '../../hooks/useAuth';
import Authentication from '../../Layouts/Authentication/Authentication';

const SingIn: React.FC = () => {
    const { signIn } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signedStatus, setSignedStatus] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false)

    const history = useHistory();

    async function handleSignIn(e: FormEvent) {
        e.preventDefault();
        setIsReadOnly(true)
        const signedGoodProcessed = await signIn({ email, password })
        setSignedStatus(!!signedGoodProcessed);

        if (!signedGoodProcessed) {
            history.push('/admin')
        }

        setTimeout(()=>{
            setIsReadOnly(false)
            setSignedStatus(false)
        }, 3000)

    }

    function handleRedirectToPageRequestNewPassword(e: FormEvent) {
        e.preventDefault();
        history.push('/forgot')
    }

    return (
        <Authentication>
            <FormAuth >
                <h1 className="h3 mb-3 font-weight-normal">Signin</h1>
                <Input
                    key="inputEmailm´ms´vs"
                    type="email"
                    label="Email"
                    name="email"
                    id="inputEmail"
                    readOnly={isReadOnly}
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    key="inputPasswordnvsbnsp"
                    label="Senha"
                    name="password"
                    type="password"
                    id="inputPassword"
                    readOnly={isReadOnly}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required />

                <div
                 className={signedStatus ? `alert alert-danger` : 'd-none'}>
                    Usuário e senha incorretos
                </div>
                <div className="button-group">

                    <button
                        className="btn btn-lg btn-primary btn-block"
                        onClick={handleSignIn}
                        disabled={isReadOnly}
                        type="submit">Sign in</button>

                    <button
                        className="btn btn-link"
                        type="button"
                        disabled={isReadOnly}
                        onClick={handleRedirectToPageRequestNewPassword}
                    >Esqueci a senha</button>
                </div>
            </FormAuth>
        </Authentication>
    )
}

export default SingIn