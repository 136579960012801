import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound404 from '../pages/Errors/NotFound404';
import PrivateRoute from './PrivateRoutes';
import SiteRoutes from './SiteRouters';


const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={['/login', '/forgot', '/admin']} component={PrivateRoute} />
                <Route path="/" component={SiteRoutes} />
                <Route component={NotFound404} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;

