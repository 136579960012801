

function moneyFormat (num: number){

    const numberFormated = num.toLocaleString('pt-bt', {
        style: 'currency',
        currency: 'BRL'
    })
    return numberFormated;
}

export default moneyFormat;

