import React, { useState } from 'react';

interface AccordionCardProps {
    id: string,
    title: string,
    content: string
}

const AcordionCard: React.FC<AccordionCardProps> = ({ id, content, title }) => {
    const [shadows, setShadows] = useState('')

    return (
        <div className={`card  ${shadows}`}>
            <div className="card-header" id={`headding${id}`}>
                <h2 className="mb-0 ">
                    <button className="btn btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target={`#colapse${id}`}
                        aria-expanded="true"
                        aria-controls={`colapse${id}`}
                        onFocus={()=>setShadows('shadow mb-3')}
                        onBlur={()=>setShadows('')}
                        >
                        {title}
                        
                    </button>
                </h2>
            </div>

            <div id={`colapse${id}`} className="collapse " aria-labelledby={`headind${id}`} data-parent="#accordionTopic">
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <div dangerouslySetInnerHTML={{__html: content}} ></div>
                </div>
            </div>
        </div>
    )

}

export default AcordionCard;