import React, { useState } from 'react';
import { Button, ModalProps, Modal } from 'react-bootstrap';
import api from '../../services/api';
import Input from '../Inputs/Input';

interface ModalCreateClientProps extends ModalProps {
    // showModal: string;
    setShowModal: (foo: boolean) => void
    onCreate?: () => void
}

const ModalConfirm: React.FC<ModalCreateClientProps> = ({ setShowModal, onCreate, ...rest }) => {
    const [name, setName] = useState('')
    function handleClose() {
        setShowModal(false)
    };

    function handleCreateClient() {
        api.post('clients', { name }).then(response => {
            setShowModal(false);
            onCreate && onCreate();
            setName('')
        })
    }



    return (

        <Modal
            // show={show}
            // onHide={handleClose}
            backdrop="static"
            keyboard={true}
            {...rest}
        >
            <Modal.Header closeButton>
                <Modal.Title>Novo Cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input
                    name="client"
                    label="Cliente"
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={handleClose}>
                    Cancelar
                    </Button>
                <Button variant="success" onClick={handleCreateClient} >
                    Salvar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirm;