import React from 'react';
import '../../styles/components/accordion.css'

interface AccordionProps{
    children: React.ReactNode
}


const Accordion:React.FC<AccordionProps> = ({children}) => {
    return (
        <div className="accordion content-accordion" id="accordionTopic">
            {children}
        </div>
    )
}

export default Accordion;