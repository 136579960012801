import React, { FormEvent, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input';
import Site from '../../../Layouts/Site/Site';
import moneyFormat from '../../../utils/moneyFormat';
import decomposeString from '../../../utils/descomposeNumber';
import calculateFreightTaxes from '../../../utils/calculateFreightTaxes';
import "../../../styles/pages/simulation.css";
import { Link } from 'react-router-dom';

export interface tributeProps {
    currentAssets: number,
    aliquot: number,
    aliquotEfective: number,
    deduction: number,
    dueTribute: number,
    activeLiquid: number
}

const Simulation = () => {

    const [last12MonthsSale, setLast12MonthsSale] = useState<number>(0);
    const [currentMonthGrossSale, setCurrentMonthGrossSale] = useState<number>(0);

    const [salesLastMonthFreeShippingBuyer, setSalesLastMonthFreeShippingBuyer] = useState<number>(0);
    const [currentMonthSaleFreeShippingBuyer, setCurrentMonthSaleFreeShippingBuyer] = useState<number>(0);
    const [typeBusiness, setTypeBusiness] = useState<"industry" | "trade">("industry");
    const [calculeTributes, setCalculeTributes] = useState<tributeProps[]>([]);
    const [showResultSimulation, setShowResultSimulation] = useState('d-none')
    const [isSuported, setIsSuported] = useState(true)

    useEffect(() => {

        if (last12MonthsSale > 4800000 || currentMonthGrossSale > 4800000 || salesLastMonthFreeShippingBuyer > 4800000 || currentMonthSaleFreeShippingBuyer > 4800000) {
            setIsSuported(false)
            setTimeout(() => {
                window.scroll(0, 560);

            }, 500)
        } else {
            setIsSuported(true)

        }
    }, [last12MonthsSale, currentMonthGrossSale, salesLastMonthFreeShippingBuyer, currentMonthSaleFreeShippingBuyer])

    function handleSimulation(e: FormEvent) {
        e.preventDefault()
        let contextCalcules: tributeProps[] = [];
        contextCalcules = [...contextCalcules, calculateFreightTaxes(typeBusiness, last12MonthsSale, currentMonthGrossSale)];
        contextCalcules = [...contextCalcules, calculateFreightTaxes(typeBusiness, salesLastMonthFreeShippingBuyer, currentMonthSaleFreeShippingBuyer)];
        setCalculeTributes(contextCalcules)
        setShowResultSimulation('');
        setTimeout(() => {
            window.scroll(0, 560);

        }, 500)
    }

    return (
        <Site>
            <main role="main" className="container">
                <div className="container-simulation">
                    <h2>Simulador</h2>
                    <div className="content">
                        <div className="header-simulation">
                            <span className="title" >Simulador do simples nacional com e sem frete</span>
                            <small className="text muted">*Informe os seguintes dados</small>
                            <small className="text muted">*O valor mínimo deve ser 1</small>

                        </div>
                        <form >
                            <Input
                                required
                                name="last12MonthsSale"
                                label="Qual o total das suas vendas nos últimos 12  meses ?"
                                min={0}
                                type="number"
                                value={last12MonthsSale}
                                onChange={(e) => setLast12MonthsSale(parseFloat(e.target.value))}

                            />


                            <Input
                                required
                                name="currentMonthGrossSale"
                                label="Qual o total das suas vendas brutas no mês atual ?"
                                min={0}
                                type="number"
                                value={currentMonthGrossSale}
                                onChange={(e) => setCurrentMonthGrossSale(parseFloat(e.target.value))}
                            />

                            <Input
                                required
                                name="salesLastMonthFreeShippingBuyer"
                                label=" Qual o total das suas vendas nos últimos 12 meses com o frete pago pelo comprador?"
                                min={0}
                                type="number"
                                value={salesLastMonthFreeShippingBuyer}
                                onChange={(e) => setSalesLastMonthFreeShippingBuyer(parseFloat(e.target.value))}
                            />

                            <Input
                                required
                                name="currentMonthSaleFreeShippingBuyer"
                                label="Qual o total das suas vendas brutas no mês atual com frete pago pelo comprador ?"
                                min={0}
                                type="number"
                                value={currentMonthSaleFreeShippingBuyer}
                                onChange={(e) => setCurrentMonthSaleFreeShippingBuyer(decomposeString(e.target.value))}
                            />



                            <div className="row radio">
                                <Input
                                    type="radio"
                                    name="typeBusines"
                                    label="Indústria"
                                    defaultChecked
                                    onChange={() => setTypeBusiness('industry')}
                                />

                                <Input
                                    type="radio"
                                    name="typeBusines"
                                    label="Comércio"
                                    onChange={() => setTypeBusiness('trade')}

                                />
                            </div>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleSimulation}
                            >Simular</button>
                        </form>

                        {!isSuported ? (
                            <div className="alert alert-danger">
                                <p>Este simulador tem suporte para valor entre {moneyFormat(1)} e {moneyFormat(4800000)}</p>
                                Para valores diferentes da faixa citada a cima, entre em contato com nossa equipe pelo
                                <a rel="noopener noreferrer" href="https://wa.me/5511948396907/?text=Olá TRIBUTAJUS, Gostaria de tirar algumas duvidas sobre impostos ? Aguardo seu retorno !"> WhatsApp </a>
                                ou por nossos  <Link to="contacts" >meios de comunicação</Link>
                            </div>) : (<div className={`simulationResult ${showResultSimulation}`}>


                                <p>Resultado da simulação</p>

                                <div className="alert alert-light" role="alert">
                                    1.  Valor dos Impostos somando o frete pago pelo comprador: <strong>{moneyFormat(calculeTributes[0]?.dueTribute || 0)}</strong>

                                </div>
                                <div className="alert alert-light" role="alert">
                                    2.  Valor dos impostos sem o frete pago pelo comprador:  <strong>{moneyFormat(calculeTributes[1]?.dueTribute || 0)}</strong>

                                </div>


                                <div className="content-details">
                                    <div className="alert alert-warning" role="alert">
                                        Utilize um dispositivo com tela maior ou use em modo retrato para os vizualizar detalhes.
                                    </div>

                                    <button type="button"
                                        className="btn btn-primary"
                                        data-toggle="collapse"
                                        data-target="#tableDetails"
                                        aria-expanded="false"
                                        aria-controls="tableDetails"
                                    >Detalhes</button>
                                    <div className="collapse" id="tableDetails">

                                        <table className="table  mt-3"  >
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Alíquota</th>
                                                    <th scope="col">Alíquota Efetiva</th>
                                                    <th scope="col">Tributo Devido</th>
                                                    <th scope="col">Dedutor</th>
                                                    <th scope="col">Ativo Circulante</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {calculeTributes.map((e, i) => {
                                                    const { currentAssets, deduction, aliquotEfective, aliquot, dueTribute } = e;
                                                    return (
                                                        <tr key={i + 1} >
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{`${aliquot.toFixed(2) || '*-*'} %`}</td>
                                                            <td>{`${aliquotEfective.toFixed(2) || '*-*'} %`}</td>
                                                            <td>{moneyFormat(dueTribute || 0)}</td>
                                                            <td>{moneyFormat(deduction)}</td>
                                                            <td>{moneyFormat(currentAssets)}</td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        <small className="muted text">Para mais duvidas contate nossa equipe no
                                            <a rel="noopener noreferrer" href="https://wa.me/5511948396907/?text=Olá TRIBUTAJUS, Gostaria de tirar algumas duvidas sobre impostos ? Aguardo seu retorno !"> WhatsApp </a>
                                        </small>
                                    </div>
                                </div>
                            </div>)
                        }

                    </div>

                </div>
            </main >
        </Site>
    )
}

export default Simulation