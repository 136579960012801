import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import { PropsArticle } from '../../../interfaces/article';
import { PropsParamsId } from '../../../interfaces/generic';
import api from '../../../services/api';

const Suggestions = () => {

    const [articles, setArticles] = useState<PropsArticle[]>([]);
    const  {id} = useParams<PropsParamsId>()

    const loadingArticles = () => {
        api.get<PropsArticle[]>(`articles/?limite=2&exceptId=${id}`).then(response => {
            const { data } = response;
            setArticles(data)
        }).catch((reason)=>{
            console.log(reason);
            
        })

    }

    useEffect(loadingArticles, [id])


    return (
        <aside>
            <h4>Mais para você</h4>
            <div className="group-card">
                {articles.map( article => <Card key={article.id} {...article} />)}
                    
            </div>

        </aside>

    )
}


export default Suggestions;