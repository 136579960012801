import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import Share from '../../../components/Shared/Share';
import Admin from '../../../Layouts/Admin/Admin';
import '../../../styles/pages/admin/topic.css';
import { PropsTopic } from '../../../interfaces/topic';
import { PropsParamsId } from '../../../interfaces/generic';

const Acting = () => {

    const [topic, setTopic] = useState<PropsTopic>();
    const { id } = useParams<PropsParamsId>();
    const loadingTopic = () => {
        api.get(`topics/${id}`).then(response => {
            const { data } = response
            setTopic(data)
        })
    }
    useEffect(loadingTopic, [id])

    return (
        <Admin>
            <main className="container" >
                <div className="container-acting  ">
                    <div className="content">
                        <div className="card">
                            <div className="card-header">
                                Topico
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{topic?.title || ''}</h5>
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: topic?.content || '' }} />
                                <p className="card-text text-date-publication">
                                    <small className="text-muted"> {new Date(topic?.created_at || '').toLocaleDateString()} </small>
                                </p>
                                {/* <Link to={`${path}/show/${id}`} className="btn-link" >Ver mais &raquo;</Link> */}
                            </div>
                        </div>

                    </div>
                    <Share />
                </div>
            </main>
        </Admin>
    )
}


export default Acting;