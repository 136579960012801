import React from 'react';
import '../../styles/components/editor.css';
import { Editor as EditorRichText } from '@tinymce/tinymce-react';
import { handleSaveData } from "./adaptorPlugin";
interface EditorProps {
    name: string;
    label: string;
    data: string;
    onChangeData: (data: string) => void
}

const Editor: React.FC<EditorProps> = ({ name, label, data, onChangeData }) => {

    return (
        <div className="container-ckEditor form-group">
            <label htmlFor={name} >{label}</label>
            <EditorRichText
                apiKey="wi0nsaqsne5m4l4g8brwlhoptxnb3ahmud95qqbmqpmuvhpp"
                value={data}
                init={{
                    height: 200,
                    menubar: false,
                    images_upload_handler: handleSaveData,
                    plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic | alignleft aligncenter alignright |  bullist numlist outdent indent | image| help'
                }}
                onEditorChange={onChangeData}
            />
        </div>
    )

}

export default Editor;

