import React, { FormEvent, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input';
import Editor from '../../../components/Editor/Editor';
import Admin from '../../../Layouts/Admin/Admin';
import '../../../styles/pages/admin/createVideo.css';
import api from '../../../services/api';
import Loading from '../../../components/Modal/Loading';
import { useHistory } from 'react-router-dom';
import { PropsLoading } from '../../../interfaces/generic';
import { PropsVideo } from '../../../interfaces/video';

const CreateVideo = () => {
    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [description, setDescription] = useState('');
    const [idVideos, setIdVideo] = useState('xyz');
    const [showPreview, setShowPreview] = useState('d-none');
    const [loadingData, setLoadingData] = useState<PropsLoading>()
    const [showModalLoading, setShowModalLoading] = useState(false)
    const [statusPub, setStatusPub] = useState(false);
    const history = useHistory();

    useEffect(function () {
        const url = link.split('?')[1]
        const paramsUrl = new URLSearchParams(url)
        const id = paramsUrl.get('v')
        setIdVideo(id || '')
    }, [link]);

  
    function handleImageVideoPreview() {
        if (link.includes('youtube')) {
            setShowPreview('')
        } else {
            setShowPreview('d-none')
        }
    }

    function handleCreateVideo(e: FormEvent) {
        e.preventDefault();
        setShowModalLoading(true)
        const data = { title, link, description }
        api.post<PropsVideo>('videos', data)

            .then((response) => {
                const { id } = response.data
                setStatusPub(true)
                setLoadingData({ id, typePub: 'videos' })

            }).catch((err) => {
                console.error(
                    `Houve um problema ao realizar o upload no servidor`
                );
                console.log(err);
            });
    }

    function handleGoBack(e: FormEvent){
        e.preventDefault();
        history.goBack();
    }

    return (
        <Admin>
            <Loading  status={statusPub} data={loadingData} showModal={showModalLoading} text="Carregando..." />
            <form className="container container-createVideo">
                <div className="content-header">
                    <h2>Publicar Vídeo</h2>
                </div>

                <div className="content mt-2 mb-2">
                    <Input
                        name="title"
                        label="Titulo"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    />
                    <Input
                        name="linkVideo"
                        label="Link video"
                        onChange={(e) => setLink(e.target.value)}
                        value={link}
                        type="url"
                        onBlur={handleImageVideoPreview}
                        required
                    />

                    <figure className={`preview-video figure ${showPreview}`}>
                        <img src={`https://i.ytimg.com/vi/${idVideos}/default.jpg`} className="img-thumbnail" alt="preview" />
                        <figcaption className="figure-caption text-right">Preview image video</figcaption>
                    </figure>

                    <Editor
                        label="Descrição"
                        name="description"
                        onChangeData={setDescription}
                        data={description}

                    />
                </div>
                <div className="content-footer">
                    <div className="btn-group">
                        <button
                            type="button"
                            onClick={handleGoBack}
                            className="btn btn-dark">Cancelar</button>

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleCreateVideo}
                        >Publicar</button>

                    </div>
                </div>
            </form>
        </Admin>
    )
}

export default CreateVideo;