import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PropsParamsId } from '../../../interfaces/generic';
import { PropsVideo } from '../../../interfaces/video';
import api from '../../../services/api';


const VideoMain: React.FC<React.ReactNode> = ({ children }) => {

    const { id } = useParams<PropsParamsId>();

    const [video, setVideo] = useState<PropsVideo>();

    const loadingData = () => {

        api.get<PropsVideo>(`videos/${id}`).then(response => {
            const { data } = response;
            setVideo(data)
        }).catch((reason)=>{
            console.log(reason);
            
        })
        // if(data === undefined) return
    }

    useEffect(loadingData, [id]);


    return (
        <main>
            <section>
                <h2>{video?.title}</h2>
                <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: video?.description || '' }} />
                    <div className="container-iframe">
                        <iframe title="videoUnique" src={`https://www.youtube.com/embed/${video?.youtubeVideoId}`} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />

                    </div>
                </div>
            </section>
            {children}
        </main>
    )
}

export default VideoMain;