import React from 'react';
import { Link } from 'react-router-dom';

interface CardProps {
    title: string;
    items: Array<{
        text: string | JSX.Element,
        pathname?: string
        timestamp?: string
    }>

}

const CardPainel: React.FC<CardProps> = ({ title, items }) => {
    return (
        <div className="card-painel card">
            <div className="card-header">
                {title}
            </div>

            <div className="card-body">
                {items.map(item => (
                    item.pathname ? (
                        <div className="items d-flex align-items-center" >
                            <span className="card-text" >{item.text} </span>
                            <small className="text-muted" > - {item.timestamp} </small>
                            <Link className="ml-1" to={`${item?.pathname}`}> Ver </Link>
                        </div>
                    ) : (
                            <div>{item.text}</div>
                        )
                ))}
            </div>
        </div>
    )
}

export default CardPainel;