import React, { useEffect, useState } from 'react';
import Accordion from '../../../components/accordion/Accordion';
import AcordionCard from '../../../components/accordion/AccordionCard';
import api from '../../../services/api';
import { PropsTopic } from '../../../interfaces/topic';

const ActingTopics = () => {

    const [topics, setTopics] = useState<PropsTopic[]>([]);

    const loadingData = () => {
        api.get<PropsTopic[]>('topics').then(response => {
            const { data } = response;
            setTopics(data)
        }).catch((reason)=>{
            console.log(reason);
            
        })

    }

    useEffect(loadingData, [])

    return (
        <section>
            {topics.length > 0 && (

                <article className="container-accordion">

                    <div className="alert alert-dark" role="alert">Veja também </div>
                    <Accordion>
                        {topics.map(topic => (<AcordionCard key={topic.id} {...topic} />))}
                        {/* <AcordionCard id={1} title=" Você sabia que pode recuperar o ICMS-ST cobrado anteriormente, quando vende a mercadoria para outro estado ou para operações não sujeitas ao ICMS ?"
                                        text="Recuperação tributos ICMS-ST recolhidos para São Paulo, com venda da mercadoria para outro estado (Portaria CAT Nº 042, DE 21 DE MAIO DE 2018)" >
                                        <p>
                                            O “Sistema de Apuração do Complemento ou Ressarcimento do ICMS Retido por Substituição
                                            Tributária ou Antecipado”, destinado à apuração do complemento ou do ressarcimento do ICMS
                                            retido por substituição tributária (ICMS-ST), ou pago por antecipação (IA), nos termos dos artigos
                                            265, 269, 277 e 426-A do Regulamento ICMS, aprovado pelo Decreto 45.490, de 30-11-2000.
                                     </p>

                                        <strong>Efeitos da decisão do STF</strong>
                                        <p>
                                            Conforme indicado anteriormente, a decisão deste Recurso Extraordinário somente surte efeitos para o
                                            contribuinte que moveu o processo. Neste sentido, os demais contribuintes, para se beneficiarem de tal
                                            prerrogativa, precisam buscar a tutela jurisdicional, ou seja, ajuizar ação específica requerendo a exclusão do
                                            valor do ICMS da base de cálculo do PIS/Pasep e da COFINS, indicando como jurisprudência este provimento
                                            disposto pelo Supremo.
                                     </p>
                                    </AcordionCard> */}

                        {/* <AcordionCard id={2} title="Você sabia que pode obter judicialmente o direito de excluir o valor do ICMS da base de cálculo dos tributos de PIS e COFINS"
                                        text="Exclusão ICMS da base de cáculo da PIS e CONFINS.">
                                        <p>O STF (Recurso Extraordinário (RE) 574.706) julgou que é constitucional a exclusão do ICMS da base
                                        de cálculo da PIS e COFINS. Assim conforme SOLUÇÃO DE CONSULTA INTERNA COSIT Nº 13,
                                        DE 18 DE OUTUBRO DE 2018, é possível deduzir o ICMS da base de cálculo dos tributos, desde
                                        que tenha sentença de trânsito em julgado concedendo tal benefício.</p>



                                    </AcordionCard>


                                    <AcordionCard id={3}
                                        title="Você sabia que alguns produtos possuem operação monofásica de PIS e COFINS ? isso quer dizer que o comerciante atacadista e o varejista de algumas mercadorias, não pagam mais o PIS e COFINS sobre suas vendas e caso já tenha pago, poderá pedir restituição dos últimos 5 anos."
                                        text="Operação monofásica do PIS e CONFINS (Solução de consulta COSIT  Nº 225, 12 DE MAIO DE 2017)" >
                                        <p>Você sabia que alguns produtos gozam de tributação monofásica, assim se a sua empresa
                                        comercializa, ainda que no atacado, alguns dos produtos abaixo, poderá se valer este benefício
                                        fiscal e reduzir cerca de 2% de tributos, e o melhor, ainda recuperar até os últimos 5 (cinco)
                                        anos, caso tenha pago indevidamente.
                                        <br />

                                        <br />
                                        A. Comércio de medicamentos; <br />
                                        B. Cosméticos;<br />
                                        C. Produtos de Higiene;<br />
                                        D. Toucador;<br />
                                        E. Águas;<br />
                                        F. Refrigerantes;<br />
                                        G. Refrescos;<br />
                                        H. Revenda de veículos, máquinas e autopeças;<br /><br />
                                        Lista disponível em: http://sped.rfb.gov.br/arquivo/show/1638</p>

                                    </AcordionCard>
                                    <AcordionCard id={4} title="Você sabia que é possível judicialmente pedir a exclusão do ISSQN da sua própria base de cálculo, além dos tributos federais"
                                        text="Exclusão dos tributos federais e o do ISSQN da base de cáculo do ISSQN">
                                        <p>
                                            É conhecido que legislação municipal (São Paulo/SP), diz que a base de cálculo é o preço do serviço
                                            prestado, contudo o tribunal de justiça de SP (Acórdão registrado sob nº 00036797640), já
                                            considerou ser estranho adicionarem outras situações a prestação, assim o ISSQN “por dentro” não
                                            encontra respaldo, podendo ser discutido judicialmente sua redução.
                                        </p>
                                        <strong>Exemplo:</strong>
                                        <p>
                                            A título ilustrativo do cálculo, em uma nota de serviços do valor total de R$ 100.000 tem-se destaque
                                            de R$ 5.000 a título de ISS (5%) e de R$ 9.250 de PIS/Cofins (9,25% regime não cumulativo), sendo
                                            o preço do serviço, portanto, R$ 85.750. Afastado o método de cálculo por dentro e sobre os tributos
                                            federais, o ISS de 5% deveria incidir não sobre o valor total da nota (R$ 100.000), mas
                                            exclusivamente sobre o preço do serviço (R$ 85.750), de modo que o valor correto a cobrar seria de
                                            R$ 4.287,50. Por essa lógica, calculando-se o ISS “por fora” e sem a incidência sobre os tributos
                                            federais, nota-se uma redução de 14,25% da carga tributária do ISS, equivalente a uma economia de
                                            0,71% de toda a receita de serviços do contribuinte.
                                        </p>
                                    </AcordionCard> */}
                    </Accordion>
                </article>
            )}

        </section>
    )
}

export default ActingTopics;