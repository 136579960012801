import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Input from '../../components/Inputs/Input';
import jwt from 'jsonwebtoken';
import Authentication from '../../Layouts/Authentication/Authentication';
import api from '../../services/api';
import FormAuth from '../../components/Form/FormAuth';


interface ParmsProps {
    idChangePassword: string
}

interface TokenProps {
    token: string
}

interface PayloadToken {
    id: string;
    email: string;
}

const DefinePassword: React.FC = () => {
    const [redefineStatus, setRedefineStatus] = useState({ className: 'd-none', message: '' });
    const [isCheckedToken, setIsChekedToken] = useState(false);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [id, setId] = useState('');
    const [isEqualsPasswords, setIsEqualsPasswords] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(false)
    const { idChangePassword } = useParams<ParmsProps>()
    const history = useHistory();

    useEffect(() => {
        async function handleGetIdUser() {
            try {
                const secretKey = process.env.REACT_APP_TOKEN as string;
                const tokenAnonymous = jwt.sign({ id: 'anonymous' }, secretKey, { expiresIn: 2 });

                //valida token 
                const { data } = await api.post<TokenProps>(`auth/forgot/password/${idChangePassword}`,
                    { email },
                    {
                        headers: { authorization: `Bearer ${tokenAnonymous}` }
                    });

                const payloadToken = jwt.verify(data.token, secretKey) as PayloadToken;
                setToken(data.token);
                setId(payloadToken.id);
                setEmail(payloadToken.email);

                setIsChekedToken(true);

            } catch (error) {
                setIsChekedToken(false);
            }
        }
        handleGetIdUser()
    }, [idChangePassword, email])


    async function handleCreateProcessDefinePassword(e: FormEvent) {
        e.preventDefault();
        try {
            await api.put(`users/${id}`, { password, email }, { headers: { authorization: `Bearer ${token}` } })
            setRedefineStatus({
                message: `Senha redefinida.`,
                className: 'alert-success'
            })

        } catch (error) {
            console.log('Error redefine pasword', error.message);
            setRedefineStatus({
                message: `Ops, tente novamente `,
                className: 'alert-danger'
            })

        }
        setTimeout(() => { history.push('/admin') }, 3000)
    }

    function handleVeficateEquals() {
        if (password === password2 && password.length >= 8) {
            setIsReadOnly(false)
            setIsEqualsPasswords(true)
        } else {
            setIsReadOnly(true)
            setIsEqualsPasswords(false)
        }
    }

    useEffect(handleVeficateEquals, [password2, password])

    return (
        <Authentication>
            {isCheckedToken ? (

                <FormAuth >
                    <h1 className="h3 mb-3 font-weight-normal">Redefinir senha</h1>
                    <Input
                        key="inputPassword1m´ms´vs"
                        type="password"
                        label="Nova senha"
                        name="password"
                        id="inputEmail"
                        required
                        autoFocus
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Input
                        key="inputPassword2m´ms´vs"
                        type="password"
                        label="Confirme a senha"
                        name="password"
                        id="inputEmail"
                        required
                        autoFocus
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />

                    <div className={`alert ${redefineStatus.className}  `}>
                        {redefineStatus.message}
                    </div>

                    <div className={`alert alert-warning ${isEqualsPasswords ? 'd-none' : ''}`}>
                        <p>As senhas devem ser iguais !</p>
                        <p>A senha deve conter no mínimo 8 caractéres.</p>
                        
                    </div>

                    <div className="button-group">

                        <button
                            className="btn btn-lg btn-primary btn-block"
                            onClick={handleCreateProcessDefinePassword}
                            disabled={isReadOnly}
                            type="button">Pronto</button>

                    </div>
                </FormAuth>
            ) : (
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="alert alert-danger">
                            Sessão expirada
                    </div>
                    </div>
                )}
        </Authentication>
    )
}

export default DefinePassword;