import React, { useEffect, useState } from 'react';
import { CgMathPlus } from 'react-icons/cg';
import { AiOutlineClose } from 'react-icons/ai';
import CreateClient from './CreateClient';
import '../../styles/components/clientList.css'
import api from '../../services/api';

interface PropsClients {
    name: string,
    id: string
}

const ClienteList = () => {
    const [show, setShow] = useState(false);
    const [clients, setClients] = useState<PropsClients[]>([]);

    function loadingClients() {
        api.get<PropsClients[]>('clients').then(response => {
            const { data } = response
            setClients(data)
        })
    }

    function handleDeleteClient(clientId: string){
        api.delete(`clients/${clientId}`).then((response)=>{
            loadingClients()
        })

    }

    useEffect(loadingClients, [])

    return (
        <div className="container-clients">

            <CreateClient
                onHide={() => setShow(false)}
                setShowModal={setShow}
                show={show}
                onCreate={loadingClients}
            />
            <div className="header">
                <h2>Clientes</h2>
                <div className="btn btn-light"
                    onClick={() => setShow(true)}
                >
                    <CgMathPlus size={30} color='#000' />
                </div>
            </div>
            <div className="list-group list-group-horizontal-lg">
                {clients.map(client => (

                    <div key={client.id} className="list-group-item">
                        <span className="item-content" >{client.name}</span>
                        <button
                         type="button"
                          className="btn btn-light"
                          onClick={()=> handleDeleteClient(client.id)}
                           >
                            <AiOutlineClose size={20} color="#F00" />
                        </button>
                    </div>

                ))}

            </div>
        </div>
    )
}

export default ClienteList;