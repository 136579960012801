import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound404 from '../pages/Errors/NotFound404';
import About from '../pages/Site/About/About';
import Acting from '../pages/Site/Acting/Acting';
import Article from '../pages/Site/Article/Article';
import Articles from '../pages/Site/Article/Articles';
import Contact from '../pages/Site/Contact/Contact';
import Home from '../pages/Site/Home/Home';
import Simulation from '../pages/Site/Simulation/Simulation';
import Video from '../pages/Site/Videos/Video';
import Videos from '../pages/Site/Videos/Videos';

const SiteRoutes = () => {
    return (
        < Route path="/"  >
            <Switch>

                <Route path="/" exact component={Home} />

                <Route path="/about" exact component={About} />
                {/* <Route path="/process" exact component={Process} /> */}

                <Route path="/actings" exact component={Acting} />

                <Route path="/articles" exact component={Articles} />
                <Route path="/articles/:id" exact component={Article} />

                <Route path="/videos" exact component={Videos} />
                <Route path="/videos/:id" exact component={Video} />

                <Route path="/contacts" exact component={Contact} />
                <Route path="/simulation" exact component={Simulation} />
                <Route  component={NotFound404} />

            </Switch>
        </Route >
    )
}

export default SiteRoutes;