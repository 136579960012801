import React from 'react';
import Site from '../../../Layouts/Site/Site';
import { FaWhatsapp, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import '../../../styles/pages/contact.css'
import Input from '../../../components/Inputs/Input';
import TextArea from '../../../components/Inputs/TextArea';

const Contact = () => {
    return (
        <Site>
            <main role="main" className="container-contact" >
                <section className="jumbotron">
                    <div className="container">
                        <h2 className="">Contato</h2>
                        <div className="content" >
                    
                            <article>
                                <a target="_blank" className="btn" rel="noopener noreferrer" href="https://wa.me/5511948396907/?text=Olá TRIBUTAJUS, podemos conversar ? Aguardo seu retorno !" >
                                    <FaWhatsapp color="#343a40" size={60} />
                                    <span>11 94839 6907</span>
                                </a>

                            </article>
                            <article>
                                <a target="_blank" className="btn" rel="noopener noreferrer" href="https://twitter.com/tributajus" >
                                    <FaTwitter color="#343a40" size={60} />
                                    <span>@tributajus</span>
                                </a>
                            </article>
                            <article>
                                <a target="_blank" className="btn" rel="noopener noreferrer" href="https://pt-br.facebook.com/tributajus" >
                                    <FaFacebook color="#343a40" size={60} />
                                    <span>@tributajus</span>
                                </a>
                            </article>
                            <article>
                                <a target="_blank" className="btn" rel="noopener noreferrer" href="https://instagram.com/tributajus" >
                                    <FaInstagram color="#343a40" size={60} />
                                    <span>@tributajus</span>
                                </a>
                            </article>
                            <article>
                                <a target="_blank" className="btn" rel="noopener noreferrer" href="mailto:tributajusdireito@gmail.com" >
                                    <SiGmail color="#343a40" size={60} />
                                    <span>@gmail</span>
                                </a>
                            </article>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <form name="contact" data-netlify="true" method="POST" >
                    <h2 className="form-title" >Mande sua duvida</h2>
                        <Input name="name" label="Nome" required placeholder="Ex: Carlos Andrade Filho" />
                        <Input name="whatsapp" label="Whatsapp" required type="number" placeholder="Ex: 11 94839 6907" />
                        <Input name="subject" label="Assunto" placeholder="Ex: Quitação de débitos" />
                        <Input name="email" label="Email" required type="email" placeholder=" Ex: carlos.andrade@gmail.com" />
                        <TextArea name="text" label="Texto" required placeholder="Digite aqui dua dúvida"/>

                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </form>
                </section>
            </main>
        </Site>
    )
}

export default Contact;