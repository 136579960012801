import React from 'react';
import '../../styles/components/FormAuth.css'

const FormAuth:React.FC = ({children})=>{
    return(
        <form className="form-signin">
            {children}
        </form>

    )
}

export default FormAuth;