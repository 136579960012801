import React, { useEffect, useState } from 'react';
import '../../styles/components/comment.css';
import api from '../../services/api';
import extratorURL from '../../utils/extratorURL';
import PublicComment from './PublicComment';
import {FaStar as Star} from  "react-icons/fa"
import { PropsComment } from '../../interfaces/comment';

const Comment = () => {

    const [comments, setComments] = useState<PropsComment[]>([])

    const { idPublication, category } = extratorURL(window.location.pathname)

    useEffect(loadingComments, [idPublication, category])

    function loadingComments() {
        const lenth = category.length;

        const pubType = category.substr(0, lenth-1);

        const url = `comments/${pubType}/${idPublication}`

        api.get<PropsComment[]>(url).then(response => {
            const { data } = response;
            setComments(data);
        })
    }



    return (

        <div className="content-comment ">
            <div className="comments w-100">
                <div className="alert alert-dark">Comentários</div>
                {comments.map(comment => (

                    <div key={comment.id} className="comment-item" >
                        <span>
                            {comment.isAdmin && 
                            <span> 
                                 ADM 
                                <Star size={15} color="#d3b574"  />
                             </span>}
                                <strong>{comment.author}</strong>
                        </span>
                        <small className="text-muted"> - {new Date(comment.created_at).toLocaleDateString()}</small>
                        <p>{comment.content}</p>
                    </div>
                ))}

            </div>
            <PublicComment
                typeComment={category}
                idPublication={idPublication}
                onPublish={loadingComments}
            />

        </div>
    )
}

export default Comment;
