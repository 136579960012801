import React from 'react';
import logo from '../../assets/images/logo2_bg.png'
import '../../styles/authentication-styles.css';

const Authentication: React.FC = ({ children }) => {
    return (
        <div className="container-fluid container-authentication ">
            <div className="content-logo">
                <img src={logo} alt="Logo tributajus" />
            </div>
            {children}
        </div>
    )
}


export default Authentication;