import React, { useEffect, useState } from 'react';
import { FaWhatsapp, FaFacebook, FaTwitter, FaPinterest, FaRegCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import '../../styles/components/share.css';
import extratorURL from '../../utils/extratorURL';

interface PropsShare {
    pathname?: string
}

const Share: React.FC<PropsShare> = ({ pathname }) => {
    const [href, setHref] = useState('');
    useEffect(() => {
        const { idPublication, category} = extratorURL(window.location.href)
        const linkShare =  `${window.location.origin}/${category}/${idPublication}`;
        setHref(linkShare);
    }, [pathname])



    return (
        <div className="content-share">
            <div className="header">
                <span>Compartilhe este conteúdo</span>
            </div>
            <div className="share-links">
                <a className="btn btn-light" target="_blank" rel="noopener noreferrer" href={`https://wa.me/?text=${href}`} >
                    <FaWhatsapp color="#34af23" size={35} />
                </a>
                <a className="btn btn-light" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer.php?u=${href}`} >
                    <FaFacebook color="#3b5998" size={35} />
                </a>

                <a className="btn btn-light" target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?text=${href}`} >
                    <FaTwitter color="#00aced" size={35} />
                </a>

                <a className="btn btn-light" target="_blank" rel="noopener noreferrer" href={`https://www.pinterest.com/pin/create/button/?url=${href}`} >
                    <FaPinterest color="#cb2027" size={35} />
                </a>
                <button type="button" className="btn btn-light" >

                    <CopyToClipboard
                        text={href}
                    >
                        <FaRegCopy color="#000" size={35} />
                    </CopyToClipboard>
                </button>
            </div>
        </div >)
}

export default Share;