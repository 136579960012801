import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomeAdmin from '../pages/Admin/Painel/Panel';
import Article from '../pages/Admin/Article/Article';
import Articles from '../pages/Admin/Article/Articles';
import AlterArticle from '../pages/Admin/Article/AlterArticle';
import CreateArticle from '../pages/Admin/Article/CreateArticle';
import Video from '../pages/Admin/Videos/Video';
import Videos from '../pages/Admin/Videos/Videos';
import AlterVideo from '../pages/Admin/Videos/AlterVideo';
import CreateVideo from '../pages/Admin/Videos/CreateVideo';
import Topic from '../pages/Admin/Acting/Topic';
import Topics from '../pages/Admin/Acting/Topics';
import AlterTopic from '../pages/Admin/Acting/AlterTopic';
import CreateTopic from '../pages/Admin/Acting/CreateTopic';
import NotFound404 from '../pages/Errors/NotFound404';

const AdminRouters = () => {

    return (
        <Route >
            <Switch>
                <Route path="/admin" exact component={HomeAdmin} />
                {/*Administration Article */}
                <Route path="/admin/articles" exact component={Articles} />
                <Route path="/admin/articles/show/:id" exact component={Article} />
                <Route path="/admin/articles/create" exact component={CreateArticle} />
                <Route path="/admin/articles/alter/:id" exact component={AlterArticle} />

                {/*Administration Videos */}
                <Route path="/admin/videos" exact component={Videos} />
                <Route path="/admin/videos/show/:id" exact component={Video} />
                <Route path="/admin/videos/alter/:id" exact component={AlterVideo} />
                <Route path="/admin/videos/create" exact component={CreateVideo} />

                {/*Administration Actings */}
                <Route path="/admin/topics" exact component={Topics} />
                <Route path="/admin/topics/show/:id" exact component={Topic} />
                <Route path="/admin/topics/alter/:id" exact component={AlterTopic} />
                <Route path="/admin/topics/create" exact component={CreateTopic} />
                <Route component={NotFound404} />
            </Switch>
        </Route>
    )
}

export default AdminRouters;