import React, { useEffect, useState } from 'react';
import Card from '../../../components/Card/Card';
import { PropsArticle } from '../../../interfaces/article';
import api from '../../../services/api';

const HomeArticles = () => {

    const [articles, setArticles] = useState<PropsArticle[]>([]);

    const loadingData = () => {
        api.get<PropsArticle[]>('articles/?limit=3').then(response => {
            const { data } = response;
            setArticles(data)
        }).catch((reason)=>{
            console.log(reason);
            
        })
    }

    useEffect(loadingData, [])

    return (
        <article className="content-articles">
            {articles.length > 0 && (<>

                <div className="alert alert-dark" role="alert">
                    Acompanhe nossos artigos
                        </div>

                <div className="card-groups">
                    {articles.map(article => (
                        <Card key={article.id} {...article} />
                    ))}

                </div>
            </>)}
        </article>
    )
}

export default HomeArticles;