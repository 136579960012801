import React, { FormEvent, useState } from 'react';
import Input from '../../../components/Inputs/Input';
import Admin from '../../../Layouts/Admin/Admin';
import Editor from '../../../components/Editor/Editor';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Modal/Loading';
import { PropsLoading } from '../../../interfaces/generic';
import { PropsArticle } from '../../../interfaces/article';

const CreateArticle = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [content, setContent] = useState('')
    const [loadingData, setLoadingData] = useState<PropsLoading>()
    const [showModalLoading, setShowModalLoading] = useState(false)
    const [statusPub, setStatusPub] = useState(false);
    const history = useHistory();

    function handleCreateArticle(e: FormEvent) {
        e.preventDefault();

        const data = {
            title,
            description,
            content,
            image: 'http://localhost:3333/imageDefault.png'
        }

        setShowModalLoading(true)
        api.post<PropsArticle>('articles', data)

            .then((response) => {
                const { id } = response.data
                setStatusPub(true)
                setLoadingData({ id, typePub: 'articles' })

            }).catch((err) => {
                console.error(
                    `Houve um problema ao realizar o upload no servidor`
                );
                console.log(err);
            });
    }

    function handleGoBack(e: FormEvent) {
        e.preventDefault();
        history.goBack();
    }

    return (
        <Admin>
            <form className="container container-alterArticle">
                <Loading status={statusPub} data={loadingData} showModal={showModalLoading} text="Carregando..." />

                <div className="content-header">
                    <h2>Publicar Artigo</h2>
                </div>

                <div className="content mt-2 mb-2">
                    <Input
                        name="title"
                        label="Titulo"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    />
                    <Editor
                        name="desc"
                        label="Descrição"
                        onChangeData={setDescription}
                        data={description}

                    />
                    <Editor
                        label="Conteúdo"
                        name="content"
                        onChangeData={setContent}
                        data={content}

                    />

                </div>
                <div className="content-footer">
                    <div className="btn-group">
                        <button
                            type="button"
                            onClick={handleGoBack}
                            className="btn btn-dark">Cancelar</button>

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleCreateArticle}
                        >Publicar</button>

                    </div>
                </div>
            </form>
        </Admin>
    )
}

export default CreateArticle;