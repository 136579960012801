import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import Card from '../../../components/Card/Card';
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import LoadingRender from '../../../components/Modal/LoadingRender'
import { PropsArticle } from '../../../interfaces/article';
import api from '../../../services/api';
import '../../../styles/pages/articles.css';


const ArticleMain: React.FC = ({ children }) => {

    const [articles, setArticles] = useState<PropsArticle[]>();

    const loadingArticles = () => {
        api.get<PropsArticle[]>('articles').then(response => {
            const { data } = response;
            setArticles(data)
        }).catch((reason) => {
            console.log(reason);

        }).catch()

    }

    useEffect(loadingArticles, [])

    function handleRenderArticles() {
        if (!articles) return <></>

        if (articles.length > 0) {
            return (
                <div className="card-groups" >
                    { articles.map(article => <Card key={article.id} {...article} onDelete={loadingArticles} />)}
                </div >
            )
        } else {
            return <ComingSoon />
        }

    }

    return (

        <main role="main" className="container">
            <div className="container-articles">
                <div className="header d-flex justify-content-between">
                    <h2>Artigos</h2>
                    {children}
                </div>
                <div className="content">
                    <LoadingRender pendence={articles} render={handleRenderArticles} />

                </div>
                <Pagination />
            </div>
        </main>
    )
}


export default ArticleMain;