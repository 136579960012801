import React, { FormEvent, useState } from 'react';
import Authentication from '../../Layouts/Authentication/Authentication';
import api from '../../services/api';
import jwt from 'jsonwebtoken';
import Input from '../../components/Inputs/Input';
import FormAuth from '../../components/Form/FormAuth';

const RequestNewPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [redefineStatus, setRedefineStatus] = useState({ className: 'd-none', message: '' });
    const [isReadOnly, setIsReadOnly] = useState(false)

    async function handleCreateProcessRequestNewPassword(e: FormEvent) {
        e.preventDefault();
        setIsReadOnly(true)
        try {
            const secretKey = process.env.REACT_APP_TOKEN as string;
            const token = jwt.sign({ id: "anonymous" }, secretKey, { expiresIn: 2 });
            const authorization = `Bearer ${token}`;

            await api.post('auth/forgot/password', { email }, { headers: { authorization } })
            setRedefineStatus({
                message: `Enviamos um email para ${email}, com os seguintes passos para redefinição de senha.`,
                className: 'alert-warning'
            })

        } catch (error) {
            console.log('Error redefine pasword\n', error.message);
            setRedefineStatus({
                message: `Usuário não existe, solicite um usuário ao administrador.`,
                className: 'alert-danger'
            })
            setIsReadOnly(false)
        }
    }

    return (
        <Authentication>
            <FormAuth>
                <h1 className="h3 mb-3 font-weight-normal">Redefinir senha</h1>
                <Input
                    key="inputEmailm´ms´vs"
                    type="email"
                    label="Email"
                    name="email"
                    id="inputEmail"
                    required
                    readOnly={isReadOnly}
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />


                <div className={`alert ${redefineStatus.className}  `}>
                    {redefineStatus.message}
                </div>
                <div className="button-group">
                    <button
                        className="btn btn-lg btn-primary btn-block"
                        disabled={isReadOnly}
                        onClick={handleCreateProcessRequestNewPassword}
                        type="button">Pronto</button>

                </div>
            </FormAuth>
        </Authentication>
    )
}

export default RequestNewPassword;