import React, { FormEvent, useState } from 'react';
import Input from '../../../components/Inputs/Input';
import Admin from '../../../Layouts/Admin/Admin';
import Editor from '../../../components/Editor/Editor';
import Loading from '../../../components/Modal/Loading';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { PropsLoading } from '../../../interfaces/generic';


const CreateTopic = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [loadingData, setLoadingData] = useState<PropsLoading>()
    const [showModalLoading, setShowModalLoading] = useState(false)
    const [statusPub, setStatusPub] = useState(false);
    const history = useHistory();

    
    function handleCreateVideo(e: FormEvent) {
        e.preventDefault();
        setShowModalLoading(true)
        const data = { title, content }
        api.post('topics', data)

            .then((response) => {
                const { id } = response.data
                setStatusPub(true)
                setLoadingData({ id, typePub: 'topics' })

            }).catch((err) => {
                console.error(
                    `Houve um problema ao realizar o upload no servidor`
                );
                console.log(err);
            });
    }

    function handleGoBack(e: FormEvent){
        e.preventDefault();
        history.goBack();
    }


    return (
        <Admin>
            <Loading  status={statusPub} data={loadingData} showModal={showModalLoading} text="Carregando..." />

            <form className="container container-createTopic">
                <div className="content-header">
                    <h2>Publicar Topico</h2>
                </div>

                <div className="content mt-2 mb-2">
                    <Input
                        name="title"
                        label="Titulo"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    />

                    <Editor
                        label="Conteúdo"
                        name="content"
                        onChangeData={setContent}
                        data={content}

                    />
                </div>
                <div className="content-footer">
                    <div className="btn-group">
                        <button
                            type="button"
                            onClick={handleGoBack}
                            className="btn btn-dark">Cancelar</button>

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleCreateVideo}
                        >Publicar</button>

                    </div>
                </div>
            </form>
        </Admin>
    )
}


export default CreateTopic;