import React from 'react';
import '../../styles/admin-styles.css';
import { Link } from 'react-router-dom';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer/Footer';

const Admin: React.FC = ({ children }) => {
    return (

        <div className="container-fluid container-admin">
            <NavBar />
            <nav id="sidebarMenu" className="col-md-2 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="sidebar-sticky pt-3">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link className="nav-link active" to="/admin">Painel</Link>
                        </li>


                        <li className="btn-group">
                            <button type="button" className="btn btn-light dropdown-toggle nav-item" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Paginas
                                </button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item nav-link" to="/admin/articles">Artigos</Link>
                                <Link className="dropdown-item nav-link" to="/admin/videos">Vídeos</Link>
                                <Link className="dropdown-item nav-link" to="/admin/topics">Topicos</Link>
                            </div>
                        </li>

                    </ul>
                </div>

            </nav>


            <main >
                <div className="container-main">
                    <div className="container-imageBackground">
                        <div className="imageBackground" />
                    </div>
                    <div className="content">
                        {children}

                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Admin;