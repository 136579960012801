import React, { useEffect, useState } from 'react';
import { CgMathPlus } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import Card from '../../../components/Card/Card.min';
import ClienteList from '../../../components/ClienteList/ClientesList';
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import { PropsTopic } from '../../../interfaces/topic';
import Admin from '../../../Layouts/Admin/Admin';
import api from '../../../services/api';

const Acting = () => {

    const [topics, setTopics] = useState<PropsTopic[]>([])

    function loadingTopics() {
        api.get<PropsTopic[]>('topics').then(response => {
            const { data } = response;
            setTopics(data)
        })
    }

    useEffect(loadingTopics, [])


    return (
        <Admin>
            <main className="container">
                <div className="container-videos">
                    <div className="header d-flex justify-content-between">
                        <h2>Topicos</h2>
                        <div className="btn btn-light">
                            <Link to="/admin/topics/create" >
                                <CgMathPlus size={30} color='#000' />
                            </Link >
                        </div>
                    </div>

                    <div className="content">
                        {topics.length > 0 ? (

                            <div className="card-groups">
                                {topics.map(topic => (
                                    <Card onDelete={loadingTopics} key={topic.id} {...topic} />
                                ))}

                            </div>
                        ) : (
                            <ComingSoon/>
                        )}
                    </div>
                    <ClienteList />
                </div>
            </main>
        </Admin>
    )
}


export default Acting;