import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import { PropsParamsId } from '../../../interfaces/generic';
import { PropsVideo } from '../../../interfaces/video';
import api from '../../../services/api';

const Suggestions = () => {

    const [videos, setVideos] = useState<PropsVideo[]>([]);
    const { id } = useParams<PropsParamsId>()

    const loadingArticles = () => {
        api.get<PropsVideo[]>(`videos/?limite=2&exceptId=${id}`).then(response => {
            const { data } = response;
            setVideos(data)
        }).catch((reason)=>{
            console.log(reason);
            
        })
    }

    useEffect(loadingArticles, [id])

    return (
        <aside>
            <h4>Mais para você</h4>
            <div className="group-card">
                {videos.map(video => <Card key={video.id} {...video} />)}
            </div>

        </aside>

    )
}


export default Suggestions;