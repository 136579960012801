import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/card.css';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import ModalConfirm from '../Modal/ModalConfirm';
import api from '../../services/api';
import LoadingDelete from '../Modal/LoadingDelete';

interface CardPros {
  id: string,
  title: string,
  description?: string,
  comments?: number,
  created_at?: string,
  image?: string,
  onDelete?: ()=> void,

  text?: string,
  coments?: number,
  created?: string
}

const Card: React.FC<CardPros> = ({ title, description, created_at, comments, image, id, onDelete }) => {

  const [path, setPath] = useState("")
  const [showHeader, setShowHeader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [typeCard, setTypeCArd] = useState('');
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const href = window.location.pathname;
    const routePage = href.split("/")

    setTypeCArd(routePage[routePage.length - 1])

    if (routePage[1] === 'admin') {
      setShowHeader(true);
      setPath(`/admin/${routePage[2]}`)

    } else {
      setShowHeader(false)
      if (routePage[1].length === 0) {
        setPath("articles");
      } else {
        setPath(routePage[1]);
      }
    }
    return
  }, []);

  function handleDeleteItem(decision: boolean) {
    if (decision) {
      setShowLoading(true)
      api.delete(`${typeCard}/${id}`).then( ()=> {
        setLoadingStatus(true);
        onDelete && onDelete();
        
      }).catch(error => {
        console.log("Erro delete item", id);

      })
    }

  }

  function handlePath(finalUrl: string) {
    if (path.includes('admin')) {
      return `${path}/show/${finalUrl}`
    } else {
      
      return `/${path}/${finalUrl}`

    }
  }

  return (
    <div className="card-personalized card m-2">
      <ModalConfirm showModal={showModal} responseModal={handleDeleteItem} />
      <LoadingDelete text="Deletando ..." status={loadingStatus} showModal={showLoading} />
      {
        showHeader && <div className="card-header d-flex justify-content-end">
          <div className="btn-group">

            <button type="button"
              className="btn btn-light nav-item"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <BsThreeDotsVertical size={20} />
            </button>

            <div className="dropdown-menu dropdown-menu-right">

              <Link to={(`${path}/alter/${id}`)} className="dropdown-item nav-link">
                Editar
                <FaEdit size={20} className="ml-2" />
              </Link>

              <button
                type="button"
                className="dropdown-item nav-link"
                onClick={() => setShowModal(!showModal)}
              >
                Excluir
              <AiFillDelete size={20} className="ml-2" />
              </button>

            </div>
          </div>
        </div>
      }

      {image ? <img src={image} className="card-img-top" alt="Imagem do artigo" /> : <div></div>}
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        {/* <p className="card-text">{description}</p> */}
        <p className="card-text" dangerouslySetInnerHTML={{ __html: description || '' }} />
        <p className="card-text text-date-publication">
          <small className="text-muted"> {new Date(created_at || '').toLocaleDateString()}
            {comments === 0 ? ' - Nenhum comentário' : ` - ${comments} comentários`}
          </small>
        </p>
        <Link to={handlePath(id)} className="btn-link" >Ver mais &raquo;</Link>
      </div>
    </div>
  )
}

export default Card;
