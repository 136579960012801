import React from 'react';
import Comment from '../../../components/Comment/Comment';
import Share from '../../../components/Shared/Share';
import Site from '../../../Layouts/Site/Site';
import ArticleMain from '../Article/Article.main';
import '../../../styles/pages/article.css';
import Suggestions from './Article.suggestions';

const Article = () => {
    return (
        <Site>
            <main className="container " >
                <div className="container-article">
                    <ArticleMain >
                        <Suggestions/>
                    </ArticleMain>
                    <Share />
                    <Comment />

                </div>
            </main>
        </Site>
    )
}


export default Article;