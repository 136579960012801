import api from '../../services/api';


export function handleSaveData(blobInfo: any, success: (url: string) => void) {
    const data = new FormData();
    const file = blobInfo.blob();

    data.append("image", file)

    api.post("images", data).then(response => {

        success(response.data.url)
    })
    return
}