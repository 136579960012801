import React from 'react';
import Comment from '../../../components/Comment/Comment';
import Share from '../../../components/Shared/Share';
import Admin from '../../../Layouts/Admin/Admin';
import ArticleMain from '../../Site/Article/Article.main';
import '../../../styles/pages/article.css';

const Article = () => {
    return (
        <Admin>
            <main className="container " >
                <div className="container-article">
                    <ArticleMain />
                    <Share />
                    <Comment />

                </div>
            </main>
        </Admin>
    )
}


export default Article;