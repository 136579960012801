import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { PropsClient } from '../../../interfaces/client';

const ActingClients = () => {

    const [clients, setClients] = useState<PropsClient[]>([]);

    const loadingData = () => {
        api.get<PropsClient[]>('clients').then(response => {
            const { data } = response;
            setClients(data)
        }).catch((reason)=>{
            console.log(reason);
            
        })

    }

    useEffect(loadingData, [])

    return (

        < section >
        {
            clients.length > 0 && (
                <>
                {/* - ANDRADE ELÉTRICA - MULTIPOWER GERADORES - CAKE FORMAS - RK MODAS - PIZZARIA VILA REAL */ }
                    <div className="alert alert-dark">
                        Nossos clientes
                </div>
                    <article>
                        {clients.map(client => (
                            <span className="alert" key={client.id}> {client.name} </span>
                        ))}
                    </article>
                </>

            )
        }
        </section >
    )
}

export default ActingClients;