import React, { useEffect, useState } from 'react';
import CardPainel from '../../../components/Card/CardPainel';
import { PropsComment } from '../../../interfaces/comment';
import api from '../../../services/api';

interface ItemsProps {
    text: string | JSX.Element,
    pathname: string,
    timestamp: string
}

const PainelShowLastComments = () => {

    const [comments, setComments] = useState<PropsComment[]>()
    const [items, setItems] = useState<ItemsProps[]>();

    function handleLoadingComments() {
        api.get<PropsComment[]>("comments/?limit=10").then(response => {
            const comments = response.data
            setComments(comments)
        })
    }

    function handleSerializeItems(){
        if (!comments) return;

        const items = comments.map(comment =>{
            return {
                text:<><strong>{comment.author}</strong> {comment.content} </> ,
                timestamp: new Date(comment.created_at).toLocaleDateString(),
                pathname: comment.pathname
            }
        })

        setItems(items)

    }

    useEffect(handleLoadingComments, []);
    useEffect(handleSerializeItems, [comments]);



    return (
        <>
            {items && <CardPainel title="Comentários" items={items} />}
        </>
    )
}

export default PainelShowLastComments;