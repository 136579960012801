import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import extratorURL from '../../utils/extratorURL';

const ComingSoon = () => {

    const [isAdmin, setAdmin] = useState(false);
    const [typePub, setTypePub] = useState('');
    useEffect(() => {
        const { pathname } = window.location;
        pathname.includes('admin') ? setAdmin(true) : setAdmin(false);
        const { category } = extratorURL(pathname);
        setTypePub(category)

    }, []);

    return (
        <div className="jumbotron jumbotron-fluid w-100 mt-2">
            <div className="container">
                {isAdmin ? (
                    <>
                        <h2 className="display-5">Faça uma publicação</h2>
                        <p className="lead d-flex align-items-center"><Link to={`/admin/${typePub}/create`} >Publicar</Link></p>
                    </>
                ) : (
                        <>
                            <h1 className="display-5">Aguarde!  </h1>
                            <p className="lead d-flex align-items-center">Em breve teremos publicações por aqui! <h1>😉</h1></p>
                        </>
                    )
                }
            </div >
        </div >
    )
}

export default ComingSoon;