import React, { FormEvent, HTMLProps, useEffect, useState } from 'react';
import Input from '../Inputs/Input';
import TextArea from '../Inputs/TextArea';
import '../../styles/components/comment.css';
import api from '../../services/api';
import extratorURL from '../../utils/extratorURL';
import useAuth from '../../hooks/useAuth';


interface PropsPublicComment extends HTMLProps<HTMLFormElement> {
    idPublication: string,
    typeComment: string,
    onPublish: () => void;
}


const PublicComment: React.FC<PropsPublicComment> = ({ onPublish, idPublication, typeComment, ...rest }) => {

    const [content, setContent] = useState('');
    const [author, setAuthor] = useState('');
    const [email, setEmail] = useState('');
    const { isAdmin } = extratorURL(window.location.pathname);
    const { user } = useAuth();


    function handleLoadDataAdmin() {
        if (!isAdmin) return;
        if (!user) return;
        setAuthor(user.name);
        setEmail(user.email)
    }

    useEffect(handleLoadDataAdmin, [isAdmin, user])

    function handlePublicComment(e: FormEvent) {
        e.preventDefault()
        const lenth = typeComment.length;
        const pubType = typeComment.substr(0, lenth-1);
        const data = { category_id: idPublication, content, author, email, isAdmin, isRead: isAdmin }

        api.post(`comments/${pubType}`, data).then(() => {
            setContent('')
            onPublish()
        })
    }

    return (
        <form {...rest} className="form"  >
            <h3>Deixe seu comentário</h3>
            <TextArea
                name="text"
                label="Comentário"
                required
                onChange={(e) => setContent(e.target.value)}
                value={content}
            />
            {!isAdmin && (
                <>
                    <Input
                        name="name"
                        label="Nome"
                        required
                        onChange={(e) => setAuthor(e.target.value)}
                    />

                    <Input
                        name="email"
                        label="E-mail"
                        required
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </>
            )}

            <p>
                <small className="text-muted"> *Seu email não será exibido nos comentários.</small>
            </p>

            <button
                onClick={handlePublicComment}
                className="btn btn-dark">
                Publicar comentário
                 </button>
        </form>
    )
}

export default PublicComment;
