import React from 'react';
import Site from '../../../Layouts/Site/Site';
import Share from '../../../components/Shared/Share';
import Comment from '../../../components/Comment/Comment';
import VideoMain from './Video.main';
import Suggestions from './Videos.suggestions';
import '../../../styles/pages/video.css';

const Video = () => {

    return (
        <Site>
            <main className="container " >
                <div className="container-video">
                    <VideoMain>
                        <Suggestions/>
                    </VideoMain>
                    <Share  />
                    <Comment />

                </div>
            </main>
        </Site>
    )
}

export default Video;