import React, { useEffect, useState } from 'react';
import { PropsVideo } from '../../../interfaces/video';
import api from '../../../services/api';


const HomeVideos = () => {

    const [videos, setVideos] = useState<PropsVideo[]>([]);

    const loadingData = () => {

        api.get<PropsVideo[]>('videos/?limit=5').then(response => {
            const { data } = response;
            setVideos(data);
        }).catch((reason)=>{
            console.log(reason);
            
        })
    }

    useEffect(loadingData, [])

    return (
        <article className="content-videos">
            {videos.length > 0 && (<>

                <div className="alert alert-dark " role="alert">
                    Videos
                    </div>
                <div className="caroucel-container">

                    <div id="carouselVideos" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            {videos.map((video, index) => (
                                <li key={video.id} data-target="#carouselVideos" className={`${index === 0 ? 'active' : ''}`} data-slide-to={index}></li>
                            ))}
                        </ol>
                        <div className="carousel-inner">

                            {videos.map((video, index) => (

                                <div key={video.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                    <iframe
                                        // key={video.id}
                                        title={video.title}
                                        width="100%"
                                        height="100%"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen src={`https://www.youtube.com/embed/${video.youtubeVideoId}`} />
                                </div>
                            ))}
                        </div>
                        <a className="carousel-control-prev" href="#carouselVideos" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Anterior</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselVideos" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Próximo</span>
                        </a>
                    </div>

                </div>
            </>)}
        </article>
    )
}

export default HomeVideos;