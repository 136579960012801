import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Modal/Loading';
import { PropsArticleShow } from '../../../interfaces/article';
import { PropsParamsId } from '../../../interfaces/generic';
import api from '../../../services/api';
import '../../../styles/pages/article.css';


const Article: React.FC<React.ReactNode> = ({ children }) => {

    const {id} = useParams<PropsParamsId>();

    const [article, setArticle] = useState<PropsArticleShow>();

    const loadingData = () => {

        api.get<PropsArticleShow>(`articles/${id}`).then(response => {
            const { data } = response;
            setArticle(data)
        }).catch((reason)=>{
            console.log(reason);
        })
        // if(data === undefined) return
    }

    useEffect(loadingData, [id]);

    if(!article){
        return <Loading showModal={true} text="Carregando..." />
    }

    return (
       
                <main>
                    <section>
                        <h2>{article?.title}</h2>
                        <div className="content">
                            <p dangerouslySetInnerHTML={{__html: article?.description || '<p/>'}} />
                            <p dangerouslySetInnerHTML={{__html: article?.content || '<p></p>'}} />
                        </div>
                    </section>
                    {children}
                </main>

           
    )
}


export default Article;