import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/pages/errorNotFound404.css';
import logoBg  from '../../assets/images/logo2_bg.png';

const NotFound404 = () => {
    return (
        <div className="container-fluid">
            <main className="container-notFound404">
                <div className="img-background">
                    <img src={logoBg}  alt=""/>
                </div>
                <div className="content">

                    <article className="first-text" >
                        <h2>
                            <h1>Ops...</h1>
                         Essa página não existe
                        </h2>
                    </article>
                    <article className="second-text">
                        <Link to="/"  >
                            Home</Link>
                    </article>
                </div>
            </main>
        </div>
    )
}

export default NotFound404;