import React, { useEffect, useState } from 'react';
import Card from '../../../components/Card/Card';
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import LoadingRender from '../../../components/Modal/LoadingRender';
import { PropsVideo } from '../../../interfaces/video';
import api from '../../../services/api';


const VideoMain: React.FC = ({ children }) => {

    const [videos, setVideos] = useState<PropsVideo[]>();


    const loadingVideos = () => {

        api.get<PropsVideo[]>('videos').then(response => {
            const { data } = response;
            setVideos(data)
        }).catch((reason) => {
            setVideos([])
        })
    }

    useEffect(loadingVideos, [])

    function handleRenderVideos() {
        if(!videos) return <></> ;
        if (videos.length > 0) {

            return (
                <div className="card-groups">
                    {videos.map(video => <Card key={video.id} {...video} onDelete={loadingVideos} />)}
                </div>
            )
        } else {
            return <ComingSoon />
        }

    }


    return (

        <main className="container" >
            <div className="container-videos">
                <div className="header d-flex justify-content-between">
                    <h2>Videos</h2>
                    {children}
                </div>
                <div className="content" >
                    <LoadingRender pendence={videos} render={handleRenderVideos} />

                </div>
            </div>
        </main>
    )
}

export default VideoMain;