import React from 'react';
import Site from '../../../Layouts/Site/Site';
import Main from './Articles.Main';
import '../../../styles/pages/articles.css';

const Articles = () => {
    return (
        <Site>
            <Main />
        </Site>
    )
}


export default Articles;