import React from 'react';
import { FaWhatsapp, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
import '../../styles/components/footer.css';

const Footer = () =>{
    return(
        <footer className="footer">
        <section>
            <article className="container-contact" >
                <span className="title" >Nossos contatos</span>
                <div className="content-contact">
                    <a target="_blank" rel="noopener noreferrer" href='https://wa.me/5511948396907/?text=Olá TRIBUTAJUS, podemos conversar ? Aguardo seu retorno !' >
                        <FaWhatsapp color="#34af23" size={35} />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://pt-br.facebook.com/tributajus" >
                        <FaFacebook color="#3b5998" size={35} />
                    </a>

                    <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/tributajus" >
                        <FaInstagram color="#3f729b" size={35} />
                    </a>

                    <a target="_blank" rel="noopener noreferrer" href="mailto:tributajus.contato@gmail.com" >
                        <SiGmail color="#dd4b39" size={35} />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/tributajus" >
                        <FaTwitter color="#00aced" size={35} />
                    </a>
                </div>
            </article>

            <article >

            </article>

            <article className="container-partners" >
                <span className="title" >Parceiros</span>
                <a target="_blank" rel="noopener noreferrer" href="https://juridicocdp.com.br/">Juridico-CDP</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.eacontabilidade.com.br/">E&A Contabilidade</a>
            </article>

        </section>

        <span>
            <p> &copy; TRIBUTAJUS - Todos os direitos reservados - 2020</p>
            <small className="title" >OAB/PJ SP n° 29991</small >
        </span>
    </footer>
    )
}

export default Footer;