import React from 'react';
import Site from '../../../Layouts/Site/Site';
import '../../../styles/pages/about.css';

const About = () => {
    return (
        <Site>
            <main role="main" className="container">
                <div className="container container-about">
                    <h2 className="">Sobre nós</h2>
                    <article>
                        <p>
                            <span className="pl-1" >
                                O Escritório TRIBUTAJUS,  fundado em 2014 é especializado na recuperação de créditos tributários e na defesa dos contribuintes. Fundado pelos advogados Julio Cesar dos Santos e Gilmar dos Santos e pelo contador Ezequiel dos Santos Amaral.
                            </span>
                        </p>
                    </article>

                    <article>
                        <p>
                            <strong>Tem como sua missão</strong><br />
                            <span className="pl-1">
                                Ser o suporte do contribuinte nas suas relações tributárias com o fisco, buscando a elisão fiscal e defesa de seus direitos. Oferecer o suporte necessário na consultoria e na prática correta na interpretação das normas tributárias.
                        </span>
                        </p>
                    </article>

                    <article>
                        <p>
                            <strong>Visão</strong><br />
                            <span className="pl-1">
                                Ser referência na área tributária, provendo soluções completas para as mais diversas necessidades dos contribuintes, firmando parcerias e crescimento contínuo.
                            </span>
                        </p>
                    </article>

                    <article>

                        <p>
                            <strong>Valores</strong><br />
                            <span className="pl-1">
                                Para o escritório TRIBUTAJUS, temos a paixão de sermos inovadores, jovens, somos rápidos na busca dos objetivos dos nossos clientes, somos confiáveis, pois nunca comprometemos e não honramos, trabalhamos com transparência e dividimos cada passo com os nossos clientes.
                            </span>
                        </p>
                    </article>
                </div>
            </main>
        </Site>
    )
}

export default About;