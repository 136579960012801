import React from 'react';
import Admin from '../../../Layouts/Admin/Admin';
import ArticleMain from '../../Site/Videos/Videos.Main'
import { CgMathPlus } from 'react-icons/cg'
import { Link } from 'react-router-dom';

const Videos = () => {
    return (
        <Admin>
            <ArticleMain>
                <div className="btn btn-light">
                    <Link  to="/admin/videos/create" >
                        <CgMathPlus size={30} color='#000'  />
                    </Link >
                </div>
            </ArticleMain>
        </Admin>
    )
}

export default Videos;