import React from 'react';
import Comment from '../../../components/Comment/Comment';
import Share from '../../../components/Shared/Share';
import Admin from '../../../Layouts/Admin/Admin';
import VideoMain from '../../Site/Videos/Video.main';
import '../../../styles/pages/article.css';

const Video = () => {
    return (
        <Admin>
            <main className="container " >
                <div className="container-video">
                    <VideoMain />
                    <Share />
                    <Comment />

                </div>
            </main>
        </Admin>
    )
}


export default Video;