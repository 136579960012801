import React from 'react';
import { AuthProvider } from './contexts/Auth';
import Routes from './Routes/Routes';
import './styles/global-style.css';

const App = () => {
    return (
        <AuthProvider>
            <Routes />
        </AuthProvider>
    )
}

export default App;