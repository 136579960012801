import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import Footer from '../../components/Footer/Footer';
import '../../styles/site-style.css'


interface LayoutProps {
    children: React.ReactNode
}

const Site: React.FC<LayoutProps> = (props) => {

    const [pathName, setPathName] = useState('');
    useEffect(() => {
        setPathName(window.location.pathname);
        window.scrollTo(0, 0)


    }, [])

    function handleSetActiveLink(route: string) {
        return pathName.split("/").includes(route)
    }

    return (
        <div className="container-fluid container-site">
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <div className="navbar-content" >
                    <Link to="/" className="logo btn">
                        {/* <MdDonutSmall size={60} color="#d3b574" /> */}
                        <img src={logo} alt="logo" />
                        <span className="header-title" >TRIBUTA</span>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse justify-content-end" id="navbarsExampleDefault">
                    <ul className="navbar-nav">
                        <li className="nav-item ">
                            <Link className={`nav-link ${pathName === '/' ? 'active' : ''} nav-link`} to="/">HOME</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${handleSetActiveLink("about") ? 'active' : ''} nav-link`} to="/about">SOBRE</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${handleSetActiveLink("actings") ? 'active' : ''} nav-link`} to="/actings">ATUAÇÃO</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className={`nav-link ${handleSetActiveLink("process") ? 'active' : ''} nav-link`} to="/process">PROCESSOS</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link className={`nav-link ${handleSetActiveLink("articles") ? 'active' : ''} nav-link`} to="/articles">ARTIGOS</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${handleSetActiveLink("videos") ? 'active' : ''} nav-link`} to="/videos">VÍDEOS</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${handleSetActiveLink("contacts") ? 'active' : ''} nav-link`} to="/contacts">CONTATO</Link>
                        </li>
                    </ul>
                </div>
            </nav>
            {props.children}
           <Footer/>
        </div>
    )
}

export default Site;