import React from 'react';
import { Link } from 'react-router-dom';
import Site from '../../../Layouts/Site/Site';
import HomeArticles from './Home.articles';
import HomeVideos from './Home.videos';
import '../../../styles/pages/home.css';


const Home = () => {

    return (
        <Site >
            <main role="main" className="container-home" >
                <section className="jumbotron">
                    <div className="container">
                        <h2 className="">O TRIBUTAJUS</h2>
                        <p>O Escritório TRIBUTAJUS, fundado em 2014 é especializado na recuperação de créditos tributários e na defesa dos contribuintes. Fundado pelos advogados Julio Cesar dos Santos e Gilmar dos Santos e pelo contador Ezequiel dos Santos Amaral.</p>
                        <p><a className="btn btn-primary" href="/about" role="button">Saiba mais &raquo;</a></p>
                    </div>
                </section>
                <div className="container">
                    <article className="alert alert-dark" role="alert">
                        Utildade
                    </article>
                    <article className="pb-5">
                        <p>Faça uma simulação e veja o quanto pode economizar</p>
                        <Link to="/simulation" className="btn btn-primary">Simular &raquo;</Link>
                    </article>
                    <HomeArticles />
                    <HomeVideos />
                </div>
            </main>
        </Site>
    )
}

export default Home;