import React, { FormEvent, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input';
import Admin from '../../../Layouts/Admin/Admin';
import Editor from '../../../components/Editor/Editor';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import Loading from '../../../components/Modal/Loading';
import { PropsArticleShow } from '../../../interfaces/article';
import { PropsParamsId, PropsLoading } from '../../../interfaces/generic';

const AlterArticle = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState<PropsLoading>()
    const [showModalLoading, setShowModalLoading] = useState(true)
    const [statusPub, setStatusPub] = useState(false);
    const { id } = useParams<PropsParamsId>();
    const history = useHistory();

    useEffect(() => {
        api.get<PropsArticleShow>(`articles/${id}`).then((response => {
            const { title, description, image, content } = response.data;
            setTitle(title);
            setDescription(description);
            setImage(image);
            setContent(content);
            setShowModalLoading(false);
        }))
    }, [id])


    function handleUpdateArticle(e: FormEvent) {
        e.preventDefault();
        setShowModalLoading(true);

        const data = {
            title,
            description,
            content,
            image: 'http://localhost:3333/imageDefault.png' || image
        }

        api.put<PropsArticleShow>(`articles/${id}`, data).then(() => {

            setStatusPub(true)
            setLoading({ id, typePub: 'articles' })

        }).catch((err) => {
            console.error(
                `Houve um problema ao realizar o upload no servidor`
            );
            console.log(err);
        });
    }

    function handleGoBack(e: FormEvent) {
        e.preventDefault();
        history.goBack();
    }



    return (
        <Admin>
            <div className="container container-alterArticle">
                <Loading data={loading} status={statusPub} showModal={showModalLoading} text="Carregando..." />

                <div className="content-header">
                    <h2>Editar Artigo</h2>
                </div>

                <div className="content mt-2 mb-2">
                    <Input
                        name="title"
                        label="Titulo"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    />
                    <Editor
                        name="desc"
                        label="Descrição"
                        onChangeData={setDescription}
                        data={description}

                    />

                    <Editor
                        label="Conteúdo"
                        name="content"
                        onChangeData={setContent}
                        data={content}

                    />

                </div>
                <div className="content-footer">
                    <div className="btn-group">
                        <button
                            type="button"
                            onClick={handleGoBack}
                            className="btn btn-dark">Cancelar</button>

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleUpdateArticle}
                        >Salvar</button>

                    </div>
                </div>
            </div>
        </Admin>
    )
}

export default AlterArticle;