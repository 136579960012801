import React, { useState, useEffect } from 'react';
import { Modal, Spinner, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

interface ModalProps {
    text?: string,
    showModal: boolean,
    status?: boolean,
   
}

const LoadingDelete: React.FC<ModalProps> = ({ text, showModal, status }) => {
    const [show, setShow] = useState(false);
    const history = useHistory()

    function handleClose() {
        setShow(false);
        history.goBack()
    };

    useEffect(() => {
        setShow(showModal);

    }, [showModal])


    return (

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            key={(new Date()).getMilliseconds()}
        >

            {!status ? (
                <Modal.Body  >
                    <div className="d-flex align-items-center justify-content-center" >
                        <span className="mr-4" >{text}</span>
                        <Spinner animation="grow" role="status" />
                    </div>
                </Modal.Body>
            ) : (
                    <>
                        <Modal.Body  >
                            <div className="alert alert-light">
                                Deletado
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Fechar
                    </Button>
                        </Modal.Footer>
                    </>

                )}


        </Modal >
    );
}

export default LoadingDelete;