import React, { FormEvent, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input';
import Admin from '../../../Layouts/Admin/Admin';
import Editor from '../../../components/Editor/Editor';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import Loading from '../../../components/Modal/Loading';
import { PropsLoading, PropsParamsId } from '../../../interfaces/generic';
import { PropsTopic } from '../../../interfaces/topic';

const AlterTopic = () => {
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState<PropsLoading>()
    const [showModalLoading, setShowModalLoading] = useState(true)
    const [statusPub, setStatusPub] = useState(false);
    const { id } = useParams<PropsParamsId>();
    const history = useHistory();


    useEffect(() => {
        api.get<PropsTopic>(`topics/${id}`).then((response => {
            const { title, content } = response.data;
            setTitle(title);
            setContent(content);
            setShowModalLoading(false);
        }))
    }, [id])


    function handleUpdateVideo(e: FormEvent) {
        e.preventDefault();
        setShowModalLoading(true)
        const data = { title, content }
        api.put<PropsTopic>(`topics/${id}`, data).then(() => {

            setStatusPub(true)
            setLoading({ id, typePub: 'topics' })

        }).catch((err) => {
            console.error(
                `Houve um problema ao realizar o upload no servidor`
            );
            console.log(err);
        });
    }

    function handleGoBack(e: FormEvent) {
        e.preventDefault();
        history.goBack();
    }

    return (
        <Admin>
            <Loading data={loading} status={statusPub} showModal={showModalLoading} text="Carregando..." />

            <form className="container container-alterTopic">
                <div className="content-header">
                    <h2>Editar Topico</h2>
                </div>

                <div className="content mt-2 mb-2">
                    <Input
                        name="title"
                        label="Titulo"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    />

                    <Editor
                        label="Conteúdo"
                        name="content"
                        onChangeData={setContent}
                        data={content}

                    />
                </div>
                <div className="content-footer">
                    <div className="btn-group">
                        <button
                            type="button"
                            onClick={handleGoBack}
                            className="btn btn-dark">Cancelar</button>

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleUpdateVideo}
                        >Salvar</button>

                    </div>
                </div>
            </form>
        </Admin>
    )
}


export default AlterTopic;