import React from 'react';
import useAuth from '../hooks/useAuth';
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRouters from './AdminRouters';


const PrivateRoute = ()=>{

    const {signed} = useAuth();

    return signed ? <AdminRouters/> : <AuthenticationRoutes/>
}

export default PrivateRoute;