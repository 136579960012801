import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Signin from '../pages/Authenticate/Signin';
import RequestNewPassword from '../pages/Authenticate/RequestNewPassword';
import RedefinePassword from '../pages/Authenticate/RedefinePassword';

const AuthenticationRoutes = () => {

    return (
        <Route >
            <Switch>
                <Route path="/admin" exact component={Signin} />
                <Route path="/forgot/" exact component={RequestNewPassword} />
                <Route path="/forgot/password/:idChangePassword" exact component={RedefinePassword} />
            </Switch>
        </Route>
    )
}

export default AuthenticationRoutes;

