import React from 'react';
import Loading from './Loading';

interface LoadingProps{
    pendence: any | null,
    render: ()=> JSX.Element
}

const LoadingRender:React.FC<LoadingProps> = ({pendence, render})=>{

    if(pendence === undefined ){
        return  <Loading showModal={true} text="Loading"  />
    }
    return render()
}

export default LoadingRender;