import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { AiOutlineUser as UserIcon } from 'react-icons/ai';
import { GoSignOut as SignOutIcon } from 'react-icons/go';
import '../../styles/components/navBar.css';


const NavBar = () => {
    const [username, setUserName] = useState('');
    const { signOut } = useAuth();
    const history = useHistory();
    function handleSignOut() {
        signOut()
        history.push('/')
    }

    const { user } = useAuth();

    useEffect(() => {
        if (!user) return;
        setUserName(user.name)
    }, [user])

    return (
        <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <Link className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" to="/admin">TRIBUTA</Link>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="form-control form-control-dark w-100"  />
            {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" /> */}
            <ul className="navbar-nav px-3">
                <li className="nav-item d-flex text-nowrap">
                    <span className="btn btn-dark mr-2 d-flex align-items-center" >
                        < UserIcon size={25} color="#fff" />
                        {username}</span>
                    <button
                        onClick={handleSignOut}
                        className="btn btn-dark" >
                        < SignOutIcon size={25} color="#fff" />
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar;