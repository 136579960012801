export default function extratorURL(pathname: string){

    const url = pathname;
    const categories = ["articles", "videos", "topics"];
    const params = url.split('/');

    const category = params.map((str) => {
        return categories.includes(str) ? str : null
     }).filter(e => e)[0] || ''
     
    const idPublication = params[params.length-1]
    const isAdmin = !!params.includes('admin');

    return { idPublication, category, isAdmin}

}