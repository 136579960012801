import React from 'react';
import { Link } from 'react-router-dom';
import CardPainel from '../../../components/Card/CardPainel';
import Admin from '../../../Layouts/Admin/Admin';
import '../../../styles/pages/admin/painel.css';
import PainelShowLastComments from './Painel.showLastComments';

const Painel = () => {
    const items = [
        {
            text: "Ladrões assaltam banco para robar dinheiro",
            pathname: "/articles",
            timestamp: "11/11/13"
        },
        {
            text: "Ladrões assaltam banco para robar dinheiro",
            pathname: "/articles",
            timestamp: "11/11/13"

        },
        {
            text: "Ladrões assaltam banconvn vwsvonp npn ceveoonnononvdnvsnvndevnvapsenvbbvea",
            pathname: "/articles",
            timestamp: "11/11/13"

        }

    ]
    return (
        <Admin>
            <div className="container">
                <div className="container-painel">

                    <div className="content-header">
                        <h2>Painel</h2>
                    </div>

                    <div className="content">
                        <header className="header">
                            <div className="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Publicar
                                </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                    <Link className="dropdown-item" to="/admin/articles/create">Artigo</Link>
                                    <Link className="dropdown-item" to="/admin/videos/create">Video</Link>
                                    <Link className="dropdown-item" to="/admin/topics/create">Tópico / Cliente</Link>
                                </div>
                            </div>
                            <div></div>
                            <div className="alert alert-light">Usuários online 7</div>
                        </header>
                        <div className="card-groups">
                            <CardPainel title="Atividades" items={items} />
                            <PainelShowLastComments/>
                            <CardPainel title="Mais lidos" items={items} />
                        </div>
                    </div>
                </div>
            </div>
        </Admin>
    )
}

export default Painel;