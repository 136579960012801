import React from 'react';
import { CgMathPlus } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import Admin from '../../../Layouts/Admin/Admin';
import ArticleMain from '../../Site/Article/Articles.Main';


const Articles = ()=>{
    return(
        <Admin>
            <ArticleMain>
            <div className="btn btn-light">
                    <Link  to="/admin/articles/create" >
                        <CgMathPlus size={30} color='#000'  />
                    </Link >
                </div>
            </ArticleMain>
        </Admin>
    )
}

export default Articles;