import tablePGDA from '../resources/tablePGDA.json'
import { tributeProps } from '../pages/Site/Simulation/Simulation';

function calculateFreightTaxes(key: "industry" | "trade", salesValueLast12Months: number, currentSaleValueMonth: number): tributeProps {
    const typeBusinessTable = tablePGDA[key];
    const accumlatedValue = salesValueLast12Months < 0 ? 0 : salesValueLast12Months;
    const valueConsidereded = accumlatedValue === 0 && currentSaleValueMonth > 1 ? (currentSaleValueMonth) : accumlatedValue;
    const resultDefault = { currentAssets: 0, aliquot: 0, deduction: 0, dueTribute: 0, aliquotEfective: 0, activeLiquid: 0 }

    if (valueConsidereded === 0) return resultDefault;

    console.log('valueCOnsidered', valueConsidereded);

    const query = typeBusinessTable.find((range) => {
        return valueConsidereded <= range.recipe
    })

    if (query === undefined) return resultDefault;

    const { aliquot, deduction } = query;
    const currentAssets = (aliquot * valueConsidereded / 100);
    const activeLiquid = currentAssets - deduction;
    const aliquotEfective = 100 * (currentAssets - query.deduction) / valueConsidereded;
    let dueTribute = 0;
    if (accumlatedValue === 0) {
        dueTribute = aliquotEfective * (currentSaleValueMonth * 12) / 100
    } else {
        dueTribute = aliquotEfective * currentSaleValueMonth /100
    }
    // const dueTribute = aliquotEfective * (accumlatedValue === 0 ? (currentSaleValueMonth *12): accumlatedValue) / 100;
    console.log({ currentAssets, activeLiquid, aliquotEfective, dueTribute, deduction, aliquot });


    return { currentAssets, activeLiquid, aliquotEfective, dueTribute, deduction, aliquot }


}

export default calculateFreightTaxes;